<div *ngIf="vo$ | async; let vo" class="exposition-endpoint-metadata-form">
	<div *ngIf="!vo.isInViewMode">
		<form
			[formGroup]="endpointMetadataForm.form"
			class="exposition-endpoint-metadata-form__config">
			<mat-form-field [appearance]="Appearance">
				<mat-label i18n="@@exposition.metadata.form.accessPoint">
					access point
				</mat-label>
				<input
					type="text"
					id="{{ cmpId + '-access-point-input' }}"
					matInput
					[formControlName]="EndpointMetadataFormControls.AccessPoint" />
				<mat-error
					*ngIf="
						endpointMetadataForm.form
							.get(EndpointMetadataFormControls.AccessPoint)
							?.hasError(ValidationErrorKeys.Pattern)
					"
					i18n="@@general.form.errors.unauthorizedCharacters">
					forbidden-characters-present
				</mat-error>
				<mat-error
					*ngIf="
						endpointMetadataForm.form
							.get(EndpointMetadataFormControls.AccessPoint)
							?.hasError(ValidationErrorKeys.Maxlength)
					"
					i18n="@@general.form.errors.maxlength">
					max length
				</mat-error>
				<mat-error
					*ngIf="
						endpointMetadataForm.form
							.get(EndpointMetadataFormControls.AccessPoint)
							?.hasError(ValidationErrorKeys.Required)
					"
					i18n="@@general.form.errors.required">
					max length
				</mat-error>
				<mat-error
					*ngIf="
						endpointMetadataForm.form
							.get(EndpointMetadataFormControls.AccessPoint)
							?.hasError(ValidationErrorKeys.UnavailableToUse)
					"
					i18n="@@exposition.metadata.form.accessPoint.error.duplicate">
					duplicates
				</mat-error>
			</mat-form-field>

			<mat-form-field [appearance]="Appearance">
				<mat-label i18n="@@exposition.metadata.form.title">title</mat-label>
				<input
					type="text"
					id="{{ cmpId + '-title-input' }}"
					matInput
					[formControlName]="EndpointMetadataFormControls.Title" />
				<mat-error
					*ngIf="
						endpointMetadataForm.form
							.get(EndpointMetadataFormControls.Title)
							?.hasError(ValidationErrorKeys.Maxlength)
					"
					i18n="@@general.form.errors.maxlength">
					max length
				</mat-error>
				<mat-error
					*ngIf="
						endpointMetadataForm.form
							.get(EndpointMetadataFormControls.Title)
							?.hasError(ValidationErrorKeys.Required)
					"
					i18n="@@general.form.errors.required">
					max length
				</mat-error>
			</mat-form-field>

			<mat-form-field [appearance]="Appearance">
				<mat-label i18n="@@exposition.metadata.form.details">details</mat-label>
				<textarea
					[rows]="4"
					type="text"
					id="{{ cmpId + '-details-input' }}"
					matInput
					[formControlName]="EndpointMetadataFormControls.Details"></textarea>
				<mat-error
					*ngIf="
						endpointMetadataForm.form
							.get(EndpointMetadataFormControls.Details)
							?.hasError(ValidationErrorKeys.Maxlength)
					"
					i18n="@@general.form.errors.maxlength">
					max length
				</mat-error>
			</mat-form-field>
		</form>
		<app-tag-configurator
			[configuredTags]="vo.expositionMetadata.keywords | toArray"
			configMode="ActiveEdit"
			displayMode="keywords"></app-tag-configurator>
	</div>

	<div
		*ngIf="vo.isInViewMode"
		class="exposition-endpoint-metadata-form__view-mode">
		<div>
			<span
				i18n="@@exposition.metadata.form.accessPoint"
				class="exposition-endpoint-metadata-form__title">
				access point
			</span>
			{{ vo.expositionMetadata.accessPoint }}
		</div>
		<div>
			<span
				i18n="@@exposition.metadata.form.title"
				class="exposition-endpoint-metadata-form__title">
				title
			</span>
			{{ vo.expositionMetadata.title }}
		</div>
		<div>
			<span
				i18n="@@exposition.metadata.form.details"
				class="exposition-endpoint-metadata-form__title">
				description
			</span>
			{{ vo.expositionMetadata.details }}
		</div>
		<div>
			<span
				i18n="@@exposition.metadata.form.keywords"
				class="exposition-endpoint-metadata-form__title">
				title
			</span>
			<app-tag-configurator
				[configuredTags]="vo.expositionMetadata.keywords | toArray"
				configMode="View"
				displayMode="keywords"></app-tag-configurator>
		</div>
	</div>
</div>
